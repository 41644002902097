<template>
    
    <div class="container" v-if="loaded">
        <div class="row align-items-center justify-content-center h-100">
            <div class="col-sm col-lg md-auto">
                
                <h1 class="display-6 text-white mt-2">
                    <i class="bi bi-person-fill"></i>
                    Espace d'administration
                </h1>

                <br>

                <div class="card">
                    <div class="card-header">
                        <ul class="nav nav-tabs card-header-tabs">
                            <li class="nav-item">
                                <router-link :to="{name: 'Admin.groups'}" class="nav-link" exact-active-class="active" exact aria-current="true"><i class="bi-people-fill me-2"></i>Groupes</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{name: 'Admin.users'}" class="nav-link" active-class="active" aria-current="true"><i class="bi bi-person-fill me-2"></i>Utilisateurs</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{name: 'Admin.settings'}" class="nav-link" active-class="active" aria-current="true"><i class="bi bi-gear-fill me-2"></i>Paramètres</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body" style="min-height: 480px;">
                        <router-view :user-info="userInfo" />
                    </div>
                    <div class="card-footer text-end">
                        <a href="/" class="btn btn-sm btn-secondary">Retour espace utilisateur</a>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>

<script setup>

    import { onMounted, ref } from 'vue';
    import axios from 'axios';

    let userInfo = ref(null);
    let loaded = ref(false);
    let error = ref(null);
    
    onMounted( () => {
        axios.get("/api/users/me")
            .then( (answer) => {
                userInfo.value = answer.data.meResponse;
                loaded.value = true;
            })
            .catch( (e) => {
                error.value = (e.response) ? e.response.data.message : e; 
            })
    })



</script>

<style>

</style>